<template>
  <b-table
    :items="currentItems"
    :fields="fieldsTableExtraContacts"
    :tbody-tr-class="extraIconRow"
    :tbody-tr-attr="extrasFormatTypo"
    class="table-optnd table-company-list table-nostriped table-hover"
    tbody-class="td-contact-list"
    sort-by="order">

    <template #thead-top="data">
      <b-tr>
        <b-th colspan="100">{{ tableTitle }}</b-th>
      </b-tr>
    </template>

    <template v-slot:cell(type)="extra">
      <div class="d-flex align-items-center justify-content-start">
        <i v-if="extra.item.field_name=='phone' && isMobilePhone(extra.item.value)" class="h5 text-medium-gray mb-0 bi bi-whatsapp"></i>
        <i v-else :class="contactIconClass(extra.item) + ' h5 text-medium-gray mb-0'"></i>
      </div>
    </template>

    <template v-slot:cell(value)="extra">
      <span :id="simpleDigest(extra.item.value)">
        <a v-if="extra.item.field_name == 'url'" :href="addressableUrl(extra.item.value)" target="_blank" class="text-truncate">
          {{ addressableUrl(extra.item.value) }}
        </a>
        <span v-else-if="extra.item.field_name=='phone'">
          <a v-if="isMobilePhone(extra.item.value)" 
            :href="whatsAppUrl(extra.item.value)"                       
            target="_blank">
            {{ extra.item.value | prettify-phone }}
          </a>
          <span v-else>
            {{ extra.item.value | prettify-phone }}
          </span>
        </span>
        <span v-else>
          {{ extra.item.value | lowercase }}
        </span>
      </span>
      <span v-if="getExtraSource(extra.item).includes('Usuário')" 
      class="badge height-22 badge-yellow ml-3"
      title="Adicionado pelo Usuário">
        <i class="bi bi-person-fill"></i>
        <span class="d-none d-xxl-inline ml-2">Adicionado pelo Usuário</span>
      </span>      
    </template>

    <template v-slot:cell(actions)="extra">
      <div class="d-flex align-items-center justify-content-end">
        <a v-if="getExtraSource(extra.item).includes('Usuário')" 
        @click="deleteUserContact(extra.item)" 
        role="button" 
        tabindex="0" 
        class="btn btn-cnpj-actions" 
        title="Excluir este contato">
          <i class="bi bi-trash"></i>
        </a>
        <a role="button"        
        class="btn btn-cnpj-actions copia_e_cola_button ml-3"
        :target="'#'+simpleDigest(extra.item.value)"
        title="Copiar Contato">
          <i class="bi bi-copy"></i>
        </a>
      </div>
    </template>    
  </b-table>
</template>

<script>
const socialNetworkIconClass = {"foursquare": "fa fa-foursquare",
                                "linkedin": "fa fa-linkedin",
                                "tripadvisor": "fa fa-tripadvisor",
                                "pinterest": "fa fa-pinterest",
                                "wordpress": "fa fa-wordpress-simple",
                                "spotify": "fa fa-spotify",
                                "whatsapp": "bi bi-whatsapp",
                                "facebook": "fa fa-facebook-square",
                                "instagram": "fa fa-instagram",
                                "twitter": "bi bi-twitter-x",
                                "youtube": "fa fa-youtube",
                                "twitch": "bi bi-twitch",
                                "tiktok": "bi bi-tiktok",
                                "website": "bi bi-globe"};

export default {  
  props: {
    value: Object,
    contactSource: String
  },  
  data () {
    return {
        fieldsTableExtraContacts: [
          {key:'type' , label:'Tipo'},
          {key:'value', label:'Contato'},
          {key:'actions', label: ''}
      ],
      socialNetworkIconClass
    }
  },
  computed: {
    currentItems() {
      if (this.value === undefined) return []
      let filteredItems = []
      switch (this.contactSource) {
        case 'receita':
          filteredItems = this.value.extras.filter(item => item.source_message == 'fromReceitaFederal')
          break
        default:
          filteredItems = this.value.extras.filter(item => item.source_message != 'fromReceitaFederal')
      }
      return filteredItems.sort(this.sortExtras)    
    },
    tableTitle() {
        switch (this.contactSource) {
          case 'receita':
            return "Contatos da Receita Federal"
          default:
            return "Contatos da Oportunidados"
        }      
    }
  },
  methods: {
    // A hashing function from SO thread (https://stackoverflow.com/a/52171480)
    // used here just to create simple unique ids for targeting the 
    // copy-to-clipboard buttons
    simpleDigest(str, seed) {
      let h1 = 0xdeadbeef ^ seed, h2 = 0x41c6ce57 ^ seed;
      for(let i = 0, ch; i < str.length; i++) {
          ch = str.charCodeAt(i);
          h1 = Math.imul(h1 ^ ch, 2654435761);
          h2 = Math.imul(h2 ^ ch, 1597334677);
      }
      h1  = Math.imul(h1 ^ (h1 >>> 16), 2246822507);
      h1 ^= Math.imul(h2 ^ (h2 >>> 13), 3266489909);
      h2  = Math.imul(h2 ^ (h2 >>> 16), 2246822507);
      h2 ^= Math.imul(h1 ^ (h1 >>> 13), 3266489909);
    
      return 4294967296 * (2097151 & h2) + (h1 >>> 0);
    },    
    extrasRowClass(item, _type) {
      if ( !item || _type !== 'row') return
      if ( this.extraMessageIncludes(item,'typo') ) return 'table-success'
    },
    extraIconRow(extraItem) {
      switch (extraItem.field_name) {
        case 'phone':
          return "phone"
        case 'email':
          return "email"
        case 'url':
          switch (extraItem.url_type) {
            case 'website':
              return "website"
            default:
              return "social"
          }          
      }      
    },
    extrasFormatTypo(item, _type) {
      if ( !item || _type !== 'row') return
      if ( this.extraMessageIncludes(item,'typo') ) return {'title': 'Sugestão de correção para erro de digitação'}
    },
    contactIconClass(extraItem) {
      let classDesc = ''
      if (extraItem.field_name=='phone') {
        classDesc = 'bi bi-telephone-fill'
      }
      else if (extraItem.field_name=='email') {
        classDesc = 'bi bi-envelope-fill'
      }
      else if (extraItem.field_name=='url' && extraItem.url_type=='website') {
        classDesc = 'bi bi-globe'
      }
      else if (extraItem.field_name=='url') {
        classDesc = socialNetworkIconClass[extraItem.url_type]
      }
      return classDesc
    },
    isMobilePhone(phone) {            
      const regex = /(55)?(\d{2})?(\d{8,9})/
      const m = phone.match(regex) || []            
      return (m.length>0 && m[m.length-1].startsWith('9'))
    },
    whatsAppUrl(phone) {
      if (!phone.startsWith('55')) {
        phone = '55' + phone
      }
      return 'https://wa.me/' + phone
    },
    getExtraSource(extraItem) {
      if (this.extraMessageIncludes(extraItem, 'fromReceitaFederal')) {
        return 'Receita Federal'
      } else if (this.extraMessageIncludes(extraItem, 'user_added')) {
        return 'Adicionado pelo Usuário'
      } else {
        return 'Oportunidados'
      }
    },
    sortExtras(a,b) {
      if (a.field_name != b.field_name) {
        return this.sortExtrasByFieldName(a,b)
      }
      else if (a.field_name == 'url') {
        return this.sortExtrasByUrlType(a,b)
      } else {
        return -1
      }
    },
    sortExtrasByFieldName(a,b) {
      const extraFieldNameOrder = ['phone', 'email', 'url']
      return extraFieldNameOrder.indexOf(a.field_name) - extraFieldNameOrder.indexOf(b.field_name)
    },
    sortExtrasByUrlType(a,b) {
      if (a.url_type == 'website' && b.url_type != 'website') {
        return -1
      } else if (a.url_type != 'website' && b.url_type == 'website') {
        return 1
      } else {
        return a.url_type.localeCompare(b.url_type)
      }
    },
    deleteUserContact(extraItem) {      
      axios.post('/cnpjs_extra_contacts/find_and_delete_contact', extraItem)
      .then( response => {
        toastr.warning(response.data.message)        
        this.value.extras = this.value.extras.filter( (e) => { return !(e.field_name == extraItem.field_name && e.value == extraItem.value && this.extraMessageIncludes(e,'user_added')) } )
        this.$emit('input', this.value)
      }).catch( error => {
        console.log(error)
      })
    },
    extraMessageIncludes(extraItem, query) {
      let sourceMessage = extraItem.source_message
      return ( sourceMessage && sourceMessage.includes(query) )
    },
    addressableUrl(url) {
      if (!url.startsWith('http')) {
        return 'http://' + url
      }
      return url
    }
  },
  filters: {
    lowercase(value) {
      return value.toLowerCase()
    },
  },
  mounted() {
    activateCopyPasteButtons() // check: assets/javascripts/copyPaste.js
  },
  updated() {
    activateCopyPasteButtons() // check: assets/javascripts/copyPaste.js
  }
}


</script>
