<template>
  <div>
    <!-- <p class="h6">
         Se desejar, refine sua busca por município(s):
         <InfoIcon
         id="refined-by-city-info-icon"
         :help-message="refineByCityHelpMessage">                  
         </InfoIcon>
         <span class="float-right">        
         </span>
         </p> -->

    <b-form-select v-model="ufFocus"
	:options="ufList"
    id="select-uf">
        <template v-slot:first>
            <b-form-select-option value="">
	            -- Caso deseje pesquisar por municípios, escolha a UF aqui --
            </b-form-select-option>
        </template>
    </b-form-select>

    <b-collapse id="map-selector-area" v-model="someUfIsFocused" class="mb-4">
        <b-row >
            <b-col cols="12" xl="3" class="mb-4">
                <SelectableList
                    v-model="citiesSelection"
                    :source="citiesSource"
                    :data-fields="cityDataFields"
                    @change="updateCities">
                </SelectableList>
            </b-col>
            <b-col cols="12" xl="9" class="mb-4">
                <SelectableMap
                    :id="mapId"
                    v-model="citiesSelection"
                    :source="mapSource"
                    :feature-name="ufFocus"
                    @change="updateCities">
                </SelectableMap>
            </b-col>
        </b-row>

        <div class="bg-light-gray p-20 rounded-8">
            <em>Utilize a roda do mouse para aumentar ou diminuir o zoom do mapa. Segurando a tecla <b>Ctrl</b>, clique e arraste para mover o mapa. Clique uma vez na área do munícipio que desejar adicionar à lista de filtros. Clique novamente para removê-lo. Opcionalmente, selecione os municípios desejados pela lista do lado direito.</em>
        </div>
    </b-collapse>    
  </div>
</template>

<script>
 import InfoIcon from './InfoIcon';
 import SelectableMap from './BaseSelectableMap';
 import SelectableList from './BaseSelectableList';
 import {AtmoMapping} from '../utils/atmo_mapping';

 const refineByCityHelpMessage = `Utilize essa escolha apenas no caso em que você
 deseje limitar a área de busca. Caso nenhum município seja escolhido, todos os municípios
 de todos os estados marcados acima serão incluídos na busca.`
 
// const ufList = ['AC', 'AL', 'AM', 'AP', 'BA', 'CE', 'DF', 'ES', 'GO', 'MA', 'MG', 'MS', 'MT', 'PA', 'PB', 'PE', 'PI', 'PR', 'RJ', 'RN', 'RO', 'RR', 'RS', 'SC', 'SE', 'SP', 'TO']

 export default {
     components: {InfoIcon, SelectableMap, SelectableList},
     props: ["value", "ufList"],
     data () {
         return {
             ufFocus: '',
             someUfIsSelected: false,
             someUfIsFocused: false,
             citiesSelection: this.value ? this.value : [],
             cityDataFields: [ {key: 'name', label: 'Município'} ],
             mapId: 'mapId_' + uuidv4(),
             refineByCityHelpMessage
         }
     },
     computed: {
         mapSource: function () {
             if (this.someUfIsFocused) {
                 return '/data/maps/' + this.ufFocus + '_mun_q3_atm.json'
             }
             else {
                 return ''
             }
         },
         citiesSource: function () {
             if (this.someUfIsFocused) {
                 return '/data/ibge/' + this.ufFocus + '_mun_map.json'
             }
             else {
                 return ''
             }
         }
     },
     watch: {
         ufList (newValue) {
             if ( !newValue.includes(this.ufFocus) ) {
                 this.ufFocus = ""
                 this.someUfIsFocused = false
             }
         },
         ufFocus: function () {
             if ( this.ufFocus.startsWith('--') ) {
                 this.someUfIsFocused = false
             }
             else {
                 this.someUfIsFocused = ( this.ufFocus.length > 0 )
             }             
         },
         value () {
             this.citiesSelection = this.value
             if ( this.value.length == 0 ) {
                 this.ufFocus = ''
                 this.someUfIsFocused = false
             }
         }
     },
     methods: {
         updateCities() {
             this.$emit('input', this.citiesSelection)
         }
     }
 }
 
</script>
