<template>
        <b-form-group :ref="name">
            <div class="d-flex flex-wrap justify-content-between">
                <div v-for="(category,index) in categories" :key="category.id" class="group">
                    <div class="rounded-8 px-3 py-1 bg-light-blue mb-2">
                        <b-form-checkbox
                        v-if="category.hasOwnProperty('selectAll')"
                        v-model="category.selectAll"
                        @change="(checked) => toggleAllCategory(checked, category.id)">
                            <h6 class="mb-0">{{ category.header }}</h6>
                        </b-form-checkbox>

                        <div v-else class="d-flex align-items-center">
                            <h6 class="mb-0">{{ category.header }}</h6>            
                            <InfoIcon
                            v-if="category.hasOwnProperty('help')"
                            :id="icon_names[index]"
                            :help-message="category.help">
                            </InfoIcon>
                        </div>
                    </div>

                    <b-form-group :name="menu_names[index]" class="rounded-8 px-3 py-2 bg-light-gray">
                        <div v-for="option in category.options" :key="option.value" class="d-flex align-items-center">
                            <b-form-checkbox
                            v-model="category.value"
                            :value="option.value"
                            @change="(checked) => changeAction(checked, category.id)">
                            {{ option.text }}
                            </b-form-checkbox>
                            <InfoIcon
                                v-if="option.hasOwnProperty('hash')"
                                :id="option.hash"
                                :help-message="option.help">
                            </InfoIcon>
                        </div>
                    </b-form-group>
                </div> 
            </div> 
        </b-form-group>
</template>

<script>
 import InfoIcon from './InfoIcon';

 export default {
     components: {InfoIcon},
     props: ["categories", "name", "value"],
     computed: {
         menu_names () {
             return this.categories.map(category => category.id + "_menu")
         },
         icon_names () {
             return this.categories.map(category => category.id + "_icon")
         }
     },
     watch: {
         value() {
             this.categories.forEach( (category) => {             
                 this.assignValues(category)
                 this.adequateSelectAll(category)
             })
         }
     },
     methods: {
         toggleAllCategory(checked, id) {
             const idx = this.categories.findIndex( (category) => category.id == id)
             this.categories[idx].value = (checked.length>0) ? this.categories[idx].options.map(o => o.value) : []             
             const uniqueValues = [...new Set([].concat( ...this.categories.map( r=> r.value)))]
             this.$emit("input",  uniqueValues)
         },
         changeAction(checked, id) {
             const idx = this.categories.findIndex( (category) => category.id == id)
             this.adequateSelectAll(this.categories[idx])
             const uniqueValues = [...new Set([].concat( ...this.categories.map( r=> r.value)))]
             this.$emit("input",  uniqueValues)
         },
         hashIfHasHelp(element) {
             if ( element.hasOwnProperty('help') ) {
                 element.hash = uuidv4();
             }
             return element;
         },
         assignValues(category) {
             if (this.value != null) {
                 if (this.value.length == 0) {
                     category.value = []
                 }
                 else {
                     category.value = this.value.filter(v => category.options.map(o => o.value).includes(v));
                 }
             }
         },
         adequateSelectAll(category) {
             if ( category.value.length == 0 ) {
                 category.selectAll = []
             }
             else if ( category.value.length == category.options.length ) {
                 category.selectAll = [true]
             }
         }
     },
     created () {
         //category.source must be url string or Array of objects
         this.categories.forEach( (category) => {             
             if ( Array.isArray(category.source) ) {
                 category.options = category.source.map(element => this.hashIfHasHelp(element));
                 this.assignValues(category)
                 this.adequateSelectAll(category)   
             }
             else { 
                 const promise = axios.get(category.source)
                 return promise.then(response => {
                     category.options = response.data.map(element => this.hashIfHasHelp(element));
                     this.assignValues(category)
                     this.adequateSelectAll(category)
                 });
             }
         });
     }
 }
</script>
