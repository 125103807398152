<template>
    <b-form-group invalid-feedback="Valor máximo igual ou inferior ao mínimo" :state="checkRange" class="mb-0">
        <b-form-group label="A partir de:">
            <CurrencyInput v-if="isCurrency" v-model="startRange"></CurrencyInput>
            <b-form-input v-else v-model="startRange"></b-form-input>
            <b-form-text>
                {{ helpMessage.startValue }}
            </b-form-text>
        </b-form-group>
        <b-form-group label="Até:">
            <CurrencyInput v-if="isCurrency" v-model="endRange"></CurrencyInput>
            <b-form-input v-else v-model="endRange"></b-form-input>
            <b-form-text>
                {{ helpMessage.endValue }}
            </b-form-text>
        </b-form-group>
    </b-form-group>
</template>

<script>
 import CurrencyInput from './CurrencyInput';
 export default {
     components: { CurrencyInput },
     props: {
        value: Array,
        isCurrency:{
            default: false,
            type: Boolean
        },
        helpMessage: {
            type: Object,
            default() {
                return {startValue: "Valor mínimo.",
                        endValue: "Valor máximo."}
            }
        }
     },
     data() {
	 return {
             startRange: '',
             endRange: ''
	 }
     },
     computed: {
         cleanStart() {
             return this.sanitizeInput(this.startRange.toString())
         },
         cleanStartNumber() {
             return parseFloat(this.cleanStart)
         },
         cleanEnd() {
             return this.sanitizeInput(this.endRange.toString())
         },
         cleanEndNumber() {
             return parseFloat(this.cleanEnd)
         },
         range: {
             get() {                 
                 return [this.cleanStart, this.cleanEnd]
             },
             set: function(rangeValues) {
                let sanitizedRange = rangeValues.map(v => this.sanitizeInput(v.toString()))                
                this.startRange = sanitizedRange[0]
                this.endRange = sanitizedRange[1]
                return sanitizedRange
             }
         },
         checkRange() {
             if ( !isNaN(this.cleanStartNumber) && !isNaN(this.cleanEndNumber) && this.cleanEndNumber <= this.cleanStartNumber ) {
                 this.$emit('input', [this.cleanStart, ''])
                 return false
             } else {
                 this.$emit('input', this.range)
                 return true
             }
         }
     },
     methods: {
         sanitizeInput(rawInput) {
             let num = parseFloat(rawInput.replace(/[^\d\.]/g, ""))
             if (isNaN(num) || num==0) {
                 return ''
             }
             return num.toString()
         }
     },
     watch: {
         value() {
             if ( !(this.value === undefined) ) {
                 this.range = this.value    
             }             
         }         
     },
     created () {
         if ( !(this.value === undefined) ) {
             this.range = this.value
             this.startRange = this.value[0]
             this.endRange = this.value[1]
         }
     }
 }
</script>
