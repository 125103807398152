<template>
  <b-form-group label="Nome Fantasia ou Razão Social" :description="descriptionMessage">
    <b-input-group>
      <b-form-input v-model="rawInput" debounce="350">
      </b-form-input>
    </b-input-group>
  </b-form-group>
</template>

<script>
export default {
  props: ["value"],
  data() {
    return {
      rawInput: this.value,
      descriptionMessage: 'Qualquer parte do Nome Fantasia ou Razão Social. Apenas termos com mais de 1 caractere serão utilizados na busca.'
    }
  },
  watch: {
    value() {
      this.rawInput = this.value
    },
    rawInput() {
      this.handleInput()
    }
  },
  methods: {
    handleInput() {
      this.$emit('input', this.rawInput)
    }
  }
}
</script>
