<template>
  <div>
    <a class="btn btn-green mx-auto" @click.prevent="searchNewContacts(cnpj)" title="Descubra novos contatos com a Oportunidados"><i class="bi bi-stars mr-2"></i>Ferramenta de busca de contatos</a>
    <b-modal    
      :id="'search-modal-' + modalId" 
      size="xl"
      :ref="'search-modal-' + modalId"
      header-class="py-3 px-20"
      header-close-content="&#128936;"
      header-close-variant="blue opacity-hover"
      content-class="rounded-8 border-0"
      body-class="p-0"
      hide-footer>
      <template #modal-title>
        <span class="h5"><i class="bi bi-stars text-blue mr-2"></i>Ferramenta de Pesquisa Rápida de Contatos Oportunidados</span>
      </template>
      <b-tabs 
        nav-class="px-10"
        class="dashboard-tabs">
        <b-tab active>
          <template #title>
            <img src="https://lh3.googleusercontent.com/V0Lu6YzAVaCVcjSJ_4Qb0mR_idw-GApETGbkodvDKTH-rpDvHuD6J84jshR_FvXdl5mJxqbIHVdebYCCbQMJNxIxRaIHYFSq6z7laA" alt="Google Maps" width="18" height="18">
            <span>&ensp;Google Maps</span>
          </template>
          <div class="mb-4">
            <div class="mb-2">Buscando para o CNPJ {{ searchInput.cnpj | prettify_cnpj }}:</div>
              <b-row>
                <b-col cols="12" lg="6" class="mb-3">
                  <div class="bg-light-gray py-2 px-3 rounded-8 h-100">
                    <p class="text-small text-medium-gray mb-0 text-uppercase">Razão social</p>
                    <p class="mb-0">{{ searchInput.razao_social | capitalize }}</p>
                  </div>
                </b-col>
                <b-col v-show="searchInput.nome_fantasia.length" cols="12" lg="6" class="mb-3">
                  <div class="bg-light-gray py-2 px-3 rounded-8 h-100">
                    <p class="text-small text-medium-gray mb-0 text-uppercase">Nome Fantasia</p>
                    <p class="mb-0">{{ searchInput.nome_fantasia | capitalize }}</p>
                  </div>
                </b-col>
                <b-col cols="12" lg="6" class="mb-lg-0 mb-3">
                  <div class="bg-light-gray py-2 px-3 rounded-8 h-100">
                    <p class="text-small text-medium-gray mb-0 text-uppercase">Endereço</p>
                    <p class="mb-0">{{ searchInput.address | capitalize }} - {{ searchInput.cep | fix-cep }}</p>
                  </div>
                </b-col>
                <b-col cols="12" lg="6">
                  <div class="bg-light-gray py-2 px-3 rounded-8 h-100">
                    <p class="text-small text-medium-gray mb-0 text-uppercase">Cidade-UF</p>
                    <p class="mb-0">{{ searchInput.cityuf }}</p>
                  </div>
                </b-col>
              </b-row>
            </div>
          <div>
          <div v-show="searchLoading" class="row text-center">
            <div class="col-10">
              <b-spinner          
                variant="secondary">
              </b-spinner>          
            </div>
          </div>
          <div v-show="!searchLoading">
            <div v-show="gplacesCandidates.length">
              <div class="bg-light-ciano p-20 rounded-8 mb-4">
                <h6> Resultados da pesquisa no Google Maps: </h6>
                <p class="mb-0"> Ao clicar em "Adicionar contato" no menu de Ações, você adicionará os contatos disponíveis ao CNPJ procurado acima.<br>
                Os resultados abaixo podem ser de empresas diferentes da procurada!<br>
                Clique em uma linha da tabela para ressaltar a localização da mesma no mapa abaixo.</p>  
              </div>          
            </div>
            <div class="card-optnd card-table mb-4">            
                <b-table            
                  hover
                  selectable
                  show-empty
                  select-mode="single"
                  :fields="fieldsTableSearchContacts"
                  :items="gplacesCandidates"
                  @row-selected="candidateContactSelected"            
                  class="table-optnd table-fixed w-200"  
                  empty-text="Pesquisa no Google Maps não retornou resultados">
                  <template #table-colgroup="scope">
                    <col
                        v-for="field in scope.fields"
                        :key="field.key"
                        :style="{ width: field.key === 'actions' ? '123px' : field.key === 'selected' ? '40px' : ''}" >
                  </template>
                  <template v-slot:cell(selected)="row">
                    <b-form-group class="m-0 p-0 d-flex align-items-center">
                      <b-form-checkbox size="md" v-model="row.rowSelected"></b-form-checkbox>
                    </b-form-group>
                  </template>
                  <template #cell(website)="row">
                    <span class="word-break">
                      <a :href="row.item.website" target="_blank">{{ row.item.website }}</a>
                    </span>
                  </template>
                  <template #cell(actions)="row">
                    <div class="d-flex justify-content-end">
                      <div class="input-group justify-content-end">
                        <button type="button" class="btn btn-blue btn-opntd-sm dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          Ações
                        </button>
                        <div class="dropdown-menu dropdown-menu-right shadow-sm">
                          <li><a class="dropdown-item" @click="addContact(row.item)">Adicionar contatos ao CNPJ buscado</a></li>
                          <li><a class="dropdown-item" @click="findCompany(row.item.nome, row.item.endereco)">Procurar esta empresa na Oportunidados</a></li>
                        </div>
                      </div>
                    </div>              
                  </template>
                </b-table>          
              </div>        
            </div>
            <div class="rounded-8 h-100 card-clip-box">
              <div id="search-map" style="height:400px"></div>
            </div>
          </div>
        </b-tab>
        <b-tab disabled>
          <template #title>
            <img src="https://content.linkedin.com/content/dam/me/business/en-us/amp/brand-site/v2/bg/LI-Bug.svg.original.svg" alt="LinkedIn" width="18" height="18">
            <span class="text-medium-gray">&ensp;LinkedIn <small>(Em breve!)</small></span>
          </template>
        </b-tab>
        <b-tab disabled>
          <template #title>
            <img src="https://storage.googleapis.com/odds-public/assets/third_party/Facebook_Logo_Primary.png" alt="Facebook" width="18" height="18">
            <span class="text-medium-gray">&ensp;Facebook <small>(Em breve!)</small></span>
          </template>
        </b-tab>
      </b-tabs>
    </b-modal>
  </div>
</template>

<script>

export default {
  props: {
    value: Array,
    cnpj: String,
    enricherProcessUrl: String
  },
  data() {        
    return {
      fieldsTableSearchContacts: [
        {key:'selected', label:''},
        {key:'nome' , label:'Nome'},
        {key:'endereco', label:'Endereço'},
        {key:'telefone', label:'Telefone'},
        {key:'website', label:'Site'},
        {key:'actions', label: ''}
      ],      
      searchInput: {
        cnpj: '',
        razao_social: '',
        nome_fantasia: '',
        address: '',
        cityuf: '',
        lat: '',
        lon: ''
      },      
      searchMap: null,
      searchMapBounds: null,
      searchLoading: false,
      gplacesCandidates: [],
      savedExtras: [],
      modalId: uuidv4()
    }      
  },
  methods: {
    updateSearchInput(data) {      
      this.searchInput.cnpj = data.company.cnpj
      this.searchInput.razao_social = data.company.razao_social
      this.searchInput.nome_fantasia = data.company.nome_fantasia
      this.searchInput.address = data.company.tipo_logradouro + ' ' + data.company.logradouro + ' ' + data.company.numero
      this.searchInput.cep = data.company.cep
      this.searchInput.cityuf = data.company.municipio + '-' + data.company.uf
      this.searchInput.lat = data.coords.lat
      this.searchInput.lon = data.coords.lon
    },
    async initSearchMap() {
      const { Map } = await google.maps.importLibrary("maps");
      const { LatLngBounds } = await google.maps.importLibrary("core");
      this.searchMapBounds = new LatLngBounds();      
      this.searchMap = new Map(document.getElementById("search-map"), {
        center: {lat: this.searchInput.lat, lng: this.searchInput.lon},
        zoom: 16,
        mapId: "SEARCH_MAP",
        disableDefaultUI: true
      });      
    },
    async addBaseAddress() {
      const searchForAddress = true
      const places = await this.searchPlaces(searchForAddress)
      const pinProps = {
        background: "#32cd32",
        borderColor: "black",
        glyph: "CEP Original",
        glyphColor: "black"
      }
      if (places.length) {
        await this.addPlacesToMap(places, pinProps)
      }
    },
    async fetchGPlacesCandidates() {
      const places = await this.searchPlaces()
      const pinProps = {        
        glyph: "?",
        glyphColor: "black"
      }      
      if (places.length) {
        const mapMarkers = await this.addPlacesToMap(places, pinProps)        
        const result = await this.getPlacesDetails(places, mapMarkers)
      }
      return
    },
    async queryGPlacesApi(query, address=false) {
      const { Place } = await google.maps.importLibrary("places");
      const request = {
        textQuery: `${query}`,
        fields: ["displayName", "location"],
        locationBias: {lat: this.searchInput.lat, lng: this.searchInput.lon},        
        language: "pt-BR",
        maxResultCount: 5,  
        region: "br"
      };
      const { places } = await Place.searchByText(request);
      if (places.length) {
        if (address) {
          return [places[0]]
        } else {
          return places
        }        
      } else {
        console.log("No places found")
        return []
      }      
    },
    async searchPlaces(address=false) {      
      let places = []      
      let new_places = []
      let query = []
      if (address) {
        query = [`${this.searchInput.cep} - ${this.searchInput.cityuf}`]
      } else {
        query = [`${this.searchInput.razao_social} - ${this.searchInput.cityuf}`]
        if (this.searchInput.nome_fantasia.length) {
          query.push(`${this.searchInput.nome_fantasia} - ${this.searchInput.cityuf}`)
        }      
      } 
      for (let i = 0; i < query.length; i++) {
        new_places = await this.queryGPlacesApi(query[i], address)
        places.push(...new_places)
      }
      const filteredPlaces = places.filter((value, index, self) =>
        self.findIndex(v => v.id === value.id) === index
      );      
      return filteredPlaces
    },
    async getPlacesDetails(places, mapMarkers=[]) {            
      if (places.length) {   
        for (const [index, place] of places.entries()) {
          await place.fetchFields({
            fields: ["displayName", "formattedAddress", "location", "nationalPhoneNumber", "websiteURI"],
          });
          this.gplacesCandidates.push({
            nome: place.displayName,
            endereco: place.formattedAddress,
            telefone: place.nationalPhoneNumber,
            website: place.websiteURI,
            marker: mapMarkers[index]
          });
        }        
      } else {
        console.log("No places found")
      }      
    },    
    async addPlacesToMap(places, pinProps) {
      let mapMarkers = [];
      let pin = null;
      if (places.length) {
        const { AdvancedMarkerElement, PinElement } = await google.maps.importLibrary("marker");        
        places.forEach( async (place) => {      
          pin = new PinElement(pinProps);
          mapMarkers.push( new AdvancedMarkerElement({
              map: this.searchMap,
              position: place.location,
              title: place.displayName,
              content: pin.element
            })
          )
          this.searchMapBounds.extend(place.location);
          this.searchMap.fitBounds(this.searchMapBounds);          
        })        
      } else {
        console.log("No places found")
      }
      return mapMarkers
    },
    searchNewContacts(cnpj) {
      this.$refs['search-modal-' + this.modalId].show()
      this.gplacesCandidates = []
      this.searchLoading = true
      axios.get('/brazilian_companies/' + cnpj + '/company_with_coordinates')
      .then( async (response) => {        
        this.updateSearchInput(response.data)        
        await this.initSearchMap()
        await this.addBaseAddress()
        const fetchResult = await this.fetchGPlacesCandidates()
        this.searchLoading = false
        this.sendCandidatesToEnricher()
      })
      .catch(function (error) {
        console.log(error);
      });
    },
    sendCandidatesToEnricher() {
      const candidates = this.gplacesCandidates
      let contacts = candidates.filter( (c) => { return c.website !== null && c.website.length>0 && new URL(c.website).hostname.endsWith('.br') } )
      if (contacts.length) {
        contacts = contacts.map( c => { return {
          phone: (c.telefone !== null) ? c.telefone.replace(/\D/g,'') : '',
          website: new URL(c.website).hostname.replace(/^www\./,'') }
        })
        axios.post(this.enricherProcessUrl, {'contacts': contacts})
        .then( () => {          
        }).catch(error => {
          console.log(error)
        })
      }
    },
    async candidateContactSelected(item){
      const { PinElement } = await google.maps.importLibrary("marker");
      let pinUnselected = null;
      let pinSelected = null;      
      this.gplacesCandidates.forEach( (c) => {
        pinUnselected = new PinElement({background: "#ea4335", glyph: "?", glyphColor: "black"})
        c.marker.content = pinUnselected.element
      });
      if (item.length) {
        pinSelected = new PinElement({background: "#35dcea", glyph: "?", glyphColor: "#ea4335"})
        item[0].marker.content = pinSelected.element
      }
    },
    addContact(item) {
      const data = {
        cnpj: this.searchInput.cnpj,
        contacts: {
          phone: item.telefone,
          url: item.website
        }
      }
      axios.post('/cnpjs_extra_contacts/add_many_contacts', data)
      .then( response => {
        toastr.success(response.data.message)
        this.fetchExtras([this.searchInput.cnpj]).then( extras => {
          this.savedExtras = this.savedExtras.concat( extras )
          this.$emit('input', this.savedExtras)
        })
      }).catch( error => {
        toastr.error(error.response.data.error)        
      })
      this.$refs['search-modal-' + this.modalId].hide()
    },
    extractCep(address) {
      const cep = address.match(/\d{5}-\d{3}$/g)
      return cep ? cep[0] : ''
    },
    findCompany(name, full_address) {
      this.searchFilter = {nome: name, cep: [this.extractCep(full_address)]};
      axios.post('/brazilian_companies_filters/create_for_js', this.searchFilter)
           .then(response => {
             window.open('/brazilian_companies', '_blank').focus();                   
           }).catch(error => {
             alert("Ops! É possível que seu navegador esteja impedindo nosso aplicativo de abrir uma nova aba. Permita que nosso site abra novas abas e clique novamente no botão de busca.")
           })
    },    
    fetchExtras(cnpjList) {
      return axios.post('/enricher/enrich_cnpj_entries', {'cnpj': cnpjList})
                  .then(response => {
                    return response.data.data.map(item => item.attributes)
                  })
    }
  },
  filters: {
    prettify_cnpj(value) {
      return value.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')
    },
  }
}
</script>