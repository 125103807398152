<template>
  <div>

    <b-form-select v-model="ufFocus" :options="ufList" id="select-uf">
      <template v-slot:first>
        <b-form-select-option value="">
          -- Escolha primeiro a UF do munícipio desejado --
        </b-form-select-option>
      </template>
    </b-form-select>

    <b-collapse id="map-selector-area" v-model="someUfIsSelected">
      <b-row >
        <b-col cols="12" lg="6" class="mb-4">
          <SelectableList v-model="citySelection" :source="citiesSource" :data-fields="cityDataFields"
            :multiple-values="false" item-name="município" parent-name="estado">
          </SelectableList>
        </b-col>
        <b-col cols="12" lg="6" class="mb-4">
          <SelectableList v-model="bairrosSelection" :source="bairrosSource" :data-fields="bairrosDataFields"
            item-name="bairro" parent-name="município" @change="updateBairros">
          </SelectableList>
        </b-col>
      </b-row>
    </b-collapse>
  </div>
</template>

<script>
import InfoIcon from './InfoIcon';
import SelectableMap from './BaseSelectableMap';
import SelectableList from './BaseSelectableList';


export default {
  components: { InfoIcon, SelectableMap, SelectableList },
  props: ["value", "ufList"],
  data() {
    return {
      ufFocus: '',
      someUfIsSelected: false,
      someCitySelected: false,
      citySelection: this.value ? this.value.city : [],
      bairrosSelection: this.value ? this.value.bairros : [],
      cityDataFields: [{ key: 'text', label: 'Município' }],
      bairrosDataFields: [{ key: 'text', label: 'Bairro' }]
    }
  },
  computed: {
    citiesSource: function () {
      if (this.someUfIsSelected) {
        return '/data/ibge/' + this.ufFocus + '_mun_map.json'
      }
      else {
        return ''
      }
    },
    bairrosSource: function () {
      if (this.someCitySelected) {
        console.log(this.citySelection[0])
        return '/get_br_city_neighborhoods?&ibge_code=' + this.citySelection[0]
      }
      else {
        return ''
      }
    }
  },
  watch: {
    ufList(newValue) {
      if (!newValue.includes(this.ufFocus)) {
        this.ufFocus = ""
        this.someUfIsSelected = false
      }
    },
    ufFocus: function () {
      if (this.ufFocus.startsWith('--')) {
        this.someUfIsSelected = false
      }
      else {
        this.someUfIsSelected = (this.ufFocus.length > 0)
      }
    },
    citySelection: function() {
      if (this.citySelection.length > 0) {
        this.someCitySelected = true
        this.bairrosSelection = []
        const ret = {
          city: this.citySelection,
          bairros: []
        }
      this.$emit('input', ret)
      }
    },
    value() {
      this.citySelection = this.value.city
      this.bairrosSelection = this.value.bairros
      if (this.value.city.length == 0) {
        this.ufFocus = ''
        this.someUfIsSelected = false
      }
    }
  },
  methods: {
    updateBairros() {
      const ret = {
        city: this.citySelection,
        bairros: this.bairrosSelection
      }
      this.$emit('input', ret)
    }
  }
}

</script>
