<template>
  <b-card footer-tag="footer" header-tag="header" class="card-filters-area">
    <template #header>
      <span>
        <span class="filter-title">
          {{title}}
        </span>

        <InfoIcon
          v-if="helpMessage || showInfoIcon"
          id="info-icon"
          :help-message="helpMessage"
          :info-icon-action="infoIconAction">
        </InfoIcon>
      </span>

      <b-button variant="close" @click="cancelFilter()"><i class="bi bi-x-lg"></i></b-button>
    </template>

    <div>
      <slot>This is the base filter. Use me in your own component.</slot>
    </div>

    <template #footer>
      <b-button class="btn-blue" variant="none" @click="saveFilter()">Adicionar</b-button>
      <b-button class="btn-light-gray ml-3" @click="cancelFilter()">Cancelar</b-button>
    </template>
  </b-card>
</template>

<script>
 import InfoIcon from './InfoIcon';
 export default {
     components: {InfoIcon},
     props: ["title", "id", "userData", "helpMessage","showInfoIcon","infoIconAction"],
     methods: {
      saveFilter() {        
        this.$emit('save');
        this.$emit('hide');
      },
      cancelFilter() {        
        this.$emit('revert');
        this.$emit('hide');
      }
     }
 }
</script>
