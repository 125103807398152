export const spec = {
    "$schema": "https://vega.github.io/schema/vega-lite/v5.1.0.json",
    "width": "container",
    "padding": 0,
    "config": {
        "axisX": {
            "labelFontSize": 14,
            "tickColor":"#9D9D9D",
            "labelColor":"#9D9D9D"

        },
        "axisY": {
            "labelFontSize": 14,
            "tickColor":"#9D9D9D",
            "labelColor":"#9D9D9D",
            "titleColor":"#6c757d",
        }
    },
    "title": { "text": "Empresas Ativas", "anchor": "start", "fontSize": 14, "color": "#212529"},
    "description": "Número de empresas ativas no mercado",
    "data": {
        "name": "market_history_data",
	    "format": "json"
    },
    "params": [
        {"name": "trunc_date", "expr": "datetime(1965,0,1)"},
        {"name": "data_min_date", "expr": "isDefined(data('market_history_data')[0]) ? toDate(data('market_history_data')[0].date) : datetime(1965,0,1)"}
    ],    
    "layer": [
        {
            "data": {
                "values": [{"a":0}]
            },
            "mark": {"type":"image", "height":50, "width":200, "aspect":true, "align":"left", "zindex": 500, "baseline":"top"},
            "encoding": {
                "x": {
                    "datum": 0.01,
                    "field": "a",
                    "type": "quantitative",
                    "scale": {"domain": [0, 1]},
                    "axis": null
                },
                "y": {
                    "datum": 0.98,
                    "field": "a",
                    "type": "quantitative",
                    "scale": {"domain": [0, 1]},
                    "axis": null
                },
                "url": {"value": "/data/logo_website.png"}
            },            
        },
        {            
            "transform": [
                { "calculate": "toDate(datum.date)", "as": "date" },
                { "timeUnit": "utcyearmonth", "field": "date", "as": "months" },
	            { "window": [{"op": "sum", "field": "acc", "as": "active"}], "frame": [null, 0] }
            ],
            "encoding": {
	            "x": {"field": "months", "type": "temporal", "axis": {"format": "%Y", "grid":true}, "title": null, "scale": {"domainMin": {"expr": "trunc_date > data_min_date ? trunc_date : data_min_date"}}},
                "y": {"field": "active", "type": "quantitative", "axis": {"orient": "left", "grid":true}, "title": null }
            },
            "layer": [
                {
                    "mark": {"type": "area", "clip": true, "color": {"gradient": "linear", "x1": 0, "y1": 1, "x2": 0, "y2": 0, 
                    "stops": [{"offset": 0.0, "color": "rgba(255, 255, 255, 0.4)"}, {"offset": 1.0, "color": "rgba(61, 110, 249, 0.4)"}]}},
                    "encoding": {
                        "tooltip": [
                            {"field": "months", "type": "temporal", "title": "Data", "format": "%b %Y"},
                            {"field": "active", "type": "quantitative", "title": "Empresas ativas", "formatType": "number", "format": ",~r"}
                        ]   
                    }
                },
                {
                    "mark": {"type": "line", "clip": true, "color":"#3D6EF9"}
                }
            ]
        }
    ],
    "resolve": {
        "scale": {"x": "independent", "y": "independent"}
    }
};

