<template>
  <div class="cnpj-gmap"/>
</template>

<script>
 export default {
     name: 'cnpj-gmap',
     props: [ 'cnpj_location' ],
     data () {
        return {
            map: null
        }
     },
     async mounted() {
        try {
            const { Map } = await google.maps.importLibrary("maps");
            const { AdvancedMarkerElement } = await google.maps.importLibrary("marker");    

            const geocoder = new google.maps.Geocoder();
             
            geocoder.geocode(
                { address: this.cnpj_location }, 
                (results, status) => {
                    if (status !== 'OK' || !results[0]) {
                        throw new Error(status);
                    }
                    let map = new Map(this.$el, {
                        center: results[0].geometry.location,
                        zoom: 18,
                        mapId: "CNPJ_GMAP",
                        disableDefaultUI: true
                    });

                    //map.fitBounds(results[0].geometry.viewport);                 

                    const markerView = new AdvancedMarkerElement({
                        map,
                        position: results[0].geometry.location                    
                    });
                    this.map = map;
                }
            );
        } catch (error) {
            console.error(error);
        }
     },
 };
</script>
