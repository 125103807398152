<template>
    <div>
        <b-input-group size="sm" class="mb-1 mt-1">
            <b-form-input 
                v-model="rowFilter" 
                type="search" 
                :placeholder="filterPrompt">
            </b-form-input>
            <InfoIcon id="filterListHelp"
                help-message="O que você digitar neste campo será utilizado para filtrar a lista. Caso deseje ver todos os itens novamente, deixe este campo vazio.">
            </InfoIcon>
        </b-input-group>
        <b-table 
            :items="fetchItems" 
            :fields="dataFields" 
            :filter="rowFilter" 
            ref="tableReference"
            :tbody-tr-class="rowClass" 
            :filter-function="matchName" 
            head-variant="light" 
            :empty-text="emptyText"
            :empty-filtered-text="emptyFilteredText" 
            @row-clicked="toggleRowSelection"
            sticky-header="500px"
            style="overflow-y: scroll" 
            no-provider-filtering 
            show-empty 
            hover 
            small 
            borderless 
            outlined>
            <template v-slot:cell(name)="row">
                {{ row.item.text }}
            </template>
            <template #table-busy>
              <div class="text-center text-primary my-2">
                <b-spinner class="align-middle"></b-spinner>
                    <strong>Carregando...</strong>
              </div>
            </template>
        </b-table>
    </div>
</template>

<script>
import InfoIcon from './InfoIcon';
export default {
    components: { InfoIcon },
    props: {
        value: Array,
        source: String,
        dataFields: Array,
        multipleValues: {
            type: Boolean,
            default: true
        },
        itemName: {
            type: String,
            default: "item"
        },
        parentName: String
    },
    data() {
        return {
            rowFilter: null,
            listData: [],
            selected: []
        }
    },
    computed: {
        // TODO: Create another component just for city selection based on this base component
        citiesFromOtherStates() {
            if (this.itemName == "município") {
                return this.value.filter(ibge => !ibge.startsWith(this.listData[0].value.substr(0, 2)))
            }
            return []
        },
        filterPrompt() {
            return "Digite o nome do " + this.itemName
        },
        emptyText() {
            return "Nenhum " + this.itemName + " encontrado ou nenhum " + this.parentName + " selecionado."
        },
        emptyFilteredText() {
            return "Nenhum " + this.itemName + " encontrado"
        }
    },
    watch: {
        source(val) {
            if (val.length > 0) {
                this.$refs["tableReference"].refresh()
            }
        },
        value() {
            this.refreshSelected()
            if ( this.value.length > 0 ) {
                this.rowFilter = ''
            }
        }
    },
    methods: {
        fetchItems(ctx) {
            if (this.source.length > 0) {
                const promise = axios.get(this.source)
                return promise.then(r => {                    
                    this.listData = r.data || [];
                    this.refreshSelected();
                    return r.data || []
                })
            }
            return []
        },
        toggleRowSelection(item, index, event) {
            if (this.multipleValues) {
                const copy = this.selected
                var newSelection = []
                if (copy.includes(item.value)) {
                    newSelection = copy.filter(e => e !== item.value)
                }
                else {
                    newSelection = copy.concat([item.value])
                }
                // TODO: Create another component just for city selection based on this base component
                this.selected = newSelection.concat(this.citiesFromOtherStates)
            } else {
                this.selected = [item.value]
            }
            this.$emit('input', this.selected)
            this.$emit('change', this.selected)
        },
        reset() {
            this.selected = []
            this.$emit('input', this.selected)
            this.$emit('change', this.selected)
        },
        rowClass(item, type) {
            if (!item || type !== 'row') return
            if (this.selected.includes(item.value)) return 'table-primary'
        },
        refreshSelected() {
            // TODO: Create another component just for city selection based on this base component
            if (this.itemName == "município") {
                this.selected = this.value.filter(name => name.startsWith(this.listData[0].value.substr(0, 2)))
            }
            else {
                this.selected = this.value
            }
        },
        matchName(row, query) {
            // noAccents is defined in app.js
            return new RegExp(noAccents(query), 'i').test(noAccents(row.text));
        }
    }
} 
</script>
