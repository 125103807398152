<template>
  <span class="info-icon">
    <i v-if="infoIconAction" @click="infoIconAction" class="bi bi-info-circle-fill" ></i>
    <i v-else class="bi bi-info-circle-fill" :id="id"></i>
    <b-popover
        v-if="helpMessage"
        :target="id"
        placement="bottom"
        triggers="hover">
      <div v-html="helpMessage"></div>
    </b-popover>
  </span>
</template>

<script>
 export default {
     props: ["id", "helpMessage", "infoIconAction"]
 }
</script>
